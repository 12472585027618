<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
    class="grey lighten-4"
  >
    <!-- <v-btn
      color="warning"
      dark
      @click="clickk"
    >
      <v-icon>mdi-cog</v-icon>
    </v-btn> -->
    <!-- Dashboard Block Info' toolbar -->
    <v-toolbar
      style="z-index: 1"
      color="elevation-0"
      dense
    >
      <v-toolbar-title class="text-h2 grey--text text--darken-0 pr-8">
        {{ $store.state.main.af.title }}
      </v-toolbar-title>
      <v-spacer />

      <!-- Add NEW blocks, open and close card -->
      <v-btn
        v-if="myRole() === 'manager'"
        color="warning"
        fab
        dark
        @click="helptoolbar = !helptoolbar"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- Dashboard Block Info -->
    <v-card
      v-if="!helptoolbar"
      class="mt-0"
      color="elevation-0"
    >
      <v-row class="px-4">
        <!-- First column -->
        <v-col
          cols="12"
          md="8"
        >
          <v-card-text
            v-for="(block, i) in $store.state.main.dsb.dBlocks"
            :key="i"
            class="py-2"
          >
            <v-checkbox
              v-model="block.selected"
              :label="translateMe(block.title)"
              class="py-0 my-0"
              hide-details
              :disabled="myRole()==='viewer'"
              @click="dblockSelected({ item: i, state: block.selected, block: block})"
            />
            <!-- <div
              class="text--disabled"
            >
              {{ block }}
            </div> -->
          </v-card-text>
        </v-col>
        <!-- Second column -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card-text>
            <v-row>
              <v-spacer />
              <!-- Restore button -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="py-8"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="restoreBlocks()"
                  >
                    <v-icon>
                      mdi-restore-alert
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.restore') }}</span>
              </v-tooltip>
            </v-row>
            <!-- Target - Total survey part -->
            <div v-if="$store.state.main.dsb.dBlocks[0].selected || $store.state.main.dsb.dBlocks[1].selected">
              <validation-observer ref="observer">
                <form>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('oth.number')"
                    rules="required|positive"
                  >
                    <v-text-field
                      v-model="surveyTotal"
                      :disabled="myRole()==='viewer'"
                      :label="$t('mpref.target')"
                      type="number"
                      :error-messages="errors"
                      dense
                    />
                  </validation-provider>
                </form>
              </validation-observer>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-row class="pb-4 px-8">
          <v-spacer />
          <!-- Keep closed -->
          <v-checkbox
            v-model="dBInfo"
            :label="$t('dashboard.keep-closed')"
            class="py-0 my-0"
            hide-details
          />
        </v-row>
      </v-card-actions>
    </v-card>

    <block-dashboard
      ref="blockDashboard"
    />

    <draggable
      group="dblocks"
      handle=".handle"
      @start="drag=true"
      @end="drag=false"
    >
      <block-l-map
        v-if="isdBlockActive('oclocation')"
        ref="blockMap"
      />

      <block-prog-pie
        v-if="isdBlockActive('ocprogpie')"
        ref="blockPie"
      />

      <block-prog-tab
        v-if="isdBlockActive('ocprogtab')"
        ref="blockProgress"
      />
    </draggable>

    <!-- Dialog box: Dashbord Blocks Select variable -->
    <v-dialog
      v-model="dialogBlocks"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('mpref.select-var') }}
        </v-card-title>
        <v-card-text>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(validateBlock)">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('common.table')"
                rules="required"
              >
                <v-select
                  v-model="selectedSection"
                  :items="dataSet"
                  :error-messages="errors"
                  item-text="label[0]"
                  item-value="table[0]"
                  prepend-icon="mdi-table"
                  :label="$t('mpref.select-tbl')"
                  return-object
                  validate-on-blur
                  @change="dataSetSelected"
                />
              </validation-provider>

              <div
                v-for="(t, i) in activeBlock.variables"
                :key="t.title"
              >
                <validation-provider
                  v-if="activeBlock.question"
                  v-slot="{ errors }"
                  :name="t.title"
                  rules="required"
                >
                  <!-- Used for collecting question from select list -->
                  <v-autocomplete
                    v-model="selectedVar[i]"
                    :items="questions"
                    :error-messages="errors"
                    item-text="label"
                    item-value="id"
                    prepend-icon="mdi-map-marker-plus"
                    :label="translateMe(t.title)"
                    @change="varSelected(i)"
                  />
                </validation-provider>

                <!-- Used for collecting data in text field
                (e.g. days for Latest Submissions)-->
                <validation-provider
                  v-else
                  v-slot="{ errors }"
                  :name="t.title"
                  rules="required"
                >
                  <v-text-field
                    v-model="selectedVar[i]"
                    :type="t.type"
                    :value="t.value"
                    :error-messages="errors"
                    :label="t.title"
                  />
                </validation-provider>
              </div>

              <v-row>
                <v-btn
                  text
                  @click="dblockCancelled('aahh')"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  type="submit"
                >
                  {{ $t('common.save') }}
                </v-btn>
              </v-row>
            </form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- New SnackBarCard -->
    <snack-bar-card :snackbar="snackBar" />
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import userAccess from '@/mixins/user-access'
  import dashboard from '@/mixins/dashboard'
  import VueTimeago from 'vue-timeago'
  // import axios from 'axios'
  import draggable from 'vuedraggable'
  import BlockDashboard from './components/BlockDashboard.vue'
  import SnackBarCard from '@/components/SnackbarCard'
  // import BlockLS from './components/BlockLatestSubmissions.vue'
  import BlockLMap from './components/BlockLMap.vue'
  import BlockProgPie from './components/BlockProgPie.vue'
  import BlockProgTab from './components/BlockProgTab.vue'
  import { extend } from 'vee-validate'

  extend('positive', value => {
    return value >= 1
  })

  Vue.use(VueTimeago, {
    name: 'Timeago', // Component name, `Timeago` by default
    locale: 'en-US', // Default locale
    locales: {
      pt: require('date-fns/locale/pt'),
      ru: require('date-fns/locale/ru'),
      tr: require('date-fns/locale/tr'),
    },
  })

  export default {
    name: 'Dashboard',

    components: {
      draggable,
      BlockDashboard,
      BlockLMap,
      BlockProgPie,
      BlockProgTab,
      SnackBarCard,
    },

    mixins: [
      userAccess,
      dashboard,
    ],

    data: () => ({
      helptoolbar: true,

      dBlocksBack: [
        {
          pack: 'ocprogpie',
          title: 'mpref.prog-pie',
          selected: false,
          tblname: [],
          question: false,
          variables: [],
        },
        {
          pack: 'ocprogtab',
          title: 'mpref.daily-preg',
          selected: false,
          tblname: [],
          question: false,
          variables: [],
        },
        {
          pack: 'oclocation',
          title: 'mpref.prog-map',
          selected: false,
          tblname: [],
          question: true,
          variables: [
            { title: 'mpref.lat', value: '', type: 'string' },
            { title: 'mpref.lon', value: '', type: 'string' },
          ],
        },
      ],

      fsettingsBack: {
        surveyTotal: 100,
        syncMode: '600000',
        dBlocksInfo: true,
        dDatasetInfo: true,
      },

      activeBlock: {},

      questions: [],

      selectedSection: '',
      selectedVar: [],

      syncLinks: {},
      syncDate: '',

      // New snackbar notification
      snackBar: {
        type: 'success',
        mode: 'multi-line',
        direction: 'top center',
        timeout: 3000,
        title: '',
        text: '',
        visible: false,
      },

      // check submisions
      polling: null,
      checking: false,

      // Default no blocks
      hasBlock: false,

      // Dialogs
      dialogBlocks: false,
    }),

    computed: {
      // https://habr.com/en/post/440542/
      // https://scrimba.com/g/gvuex

      isLocation () {
        const activeDblocks = this.$store.state.main.dsb.dBlocks.filter(x => x.selected === true).map(x => x.pack)
        return activeDblocks.includes('oclocation')
      },

      // Return only selected types of datasets
      finalDataset () {
        // console.log(this.dDatasets)
        return this.dDatasets.filter(function (ds) {
          return ds.selected
        })
      },

      dataSet () {
        return this.$store.state.ocpu.tableListRules
      },

      // using v-model with vuex update surveyTotal in store
      // https://dilshankelsen.com/v-model-with-vuex/#solution-2-two-way-computed-property
      surveyTotal: {
        get () {
          return this.$store.state.main.dsb.fsettings.surveyTotal
        },
        set (value) {
          if (value > 0) {
            this.$store.commit('main/setfSetting', { el: 'surveyTotal', val: value })
            // console.log(this.$store.state.main.dsb.fsettings)
            this.dBlockSave('fsettings', this.$store.state.main.dsb.fsettings)
            this.$refs.blockPie.getData()
          } else {
            // Snackbar text
            this.snackBar = {
              type: 'error',
              mode: 'multi-line',
              title: `${this.$t('common.error')}`,
              text: `${this.$t('mpref.target')} should be more that 0`,
              visible: true,
            }
          }
        },
      },

      // Keep dBlock info open or closed
      dBInfo: {
        get () {
          return this.$store.state.main.dsb.fsettings.dBlocksInfo
        },
        set (value) {
          this.$store.commit('main/setfSetting', { el: 'dBlocksInfo', val: value })
          // console.log(this.$store.state.main.dsb.fsettings)
          this.dBlockSave('fsettings', this.$store.state.main.dsb.fsettings)
        },
      },
    },

    created () {
      const main = this.$store.state.main
      // Fetching After Navigation
      // Fetch the data when the view is created and the data is already being observed
      // https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation

      // Periodically check submissions if form state is not closed
      if (main.af.odksettings.state !== 'closed') {
        // function from mixin dashboard.js
        this.checkSubmissions()
      }
    },

    mounted () {
      const main = this.$store.state.main

      // If this a first visit get dashboard blocks from ocpu
      if (main.dsb.calls === false) {
        // Get Table list from ocpu
        this.getTableList()

        // Get block info from ocpu dBlocks - dashboarBlocks; fsettings - Form settings; dDataset - dashboardDataset
        const blocks = ['fsettings', 'dBlocks']
        blocks.forEach(type => {
          // Using function from mixin dashboard.js
          this.dBlockGet(type)
        })
      }
      this.helptoolbar = this.$store.state.main.dsb.fsettings.dBlocksInfo

      // Get if any new submissions (function from mixin dashboard.js)
      this.getSubmissions()
      this.checkSbmDate()
    },

    beforeDestroy () {
      // we need clear interval if you run checkSubmissions() function
      clearInterval(this.polling)
    },

    methods: {
      getTableList () {
        const main = this.$store.state.main
        const payload = {
          orgid: main.orgid,
          prjid: main.ap.prjid,
          formid: main.afid,
        }
        this.$store.dispatch('ocpu/ocTableListRules', payload)
      },

      translateMe (val) {
        return this.$t(val)
      },

      async validateBlock () {
        // TODO reCapcha https://habr.com/en/post/443370/

        this.$store.commit('main/setdBlockTable', {
          item: this.dblockinfo.item, tbl: this.selectedSection.table,
        })

        this.$store.commit('main/setdBlockVars', {
          item: this.dblockinfo.item, var: this.selectedVar,
        })
        // console.log(this.selectedVar)

        const result = await this.dBlockSave('dBlocks', this.$store.state.main.dsb.dBlocks)
        if (result.status === 201) {
          // console.log(this.activeBlock.pack)
          switch (this.activeBlock.pack) {
            case 'oclocation':
              this.$refs.blockMap.getData()
              break
            case 'ocprogpie':
              // console.log('progpie')
              // TODO move these two codes and one in surveyTotal into separate
              this.$store.commit('main/setfSetting', { el: 'surveyTotal', val: this.surveyTotal })
              // console.log(this.$store.state.main.dsb.fsettings)
              this.dBlockSave('fsettings', this.$store.state.main.dsb.fsettings)
              this.$refs.blockPie.getData()
              break
            case 'ocprogtab':
              // console.log('progtab')
              this.$store.commit('main/setfSetting', { el: 'surveyTotal', val: this.surveyTotal })
              // console.log(this.$store.state.main.dsb.fsettings)
              this.dBlockSave('fsettings', this.$store.state.main.dsb.fsettings)
              this.$refs.blockProgress.getData()
              break
            default:
            // console.log('default')
          }
        }
        // console.log(result)
        this.dialogBlocks = false
        this.selectedSection = ''
        this.selectedVar = []
      },

      // When one of dBlock checkboxes are selected / unselected
      async dblockSelected (prop) {
        this.activeBlock = prop.block
        // console.log(this.activeBlock)
        if (prop.state) {
          this.dialogBlocks = true

          const block = JSON.parse(JSON.stringify(this.activeBlock))
          this.$store.commit('main/setdBlockState', block)
        } else {
          // console.log(prop.block.pack)
          const dBlockBack = JSON.parse(JSON.stringify(this.dBlocksBack))
          const block = dBlockBack.filter(x => x.pack === prop.block.pack)
          this.$store.commit('main/setdBlockState', block)
          this.dBlockSave('dBlocks', this.$store.state.main.dsb.dBlocks)
          // this.savefsettings()
        }
        this.dblockinfo = prop
      },

      dblockCancelled () {
        // console.log(this.activeBlock)
        const dBlockBack = JSON.parse(JSON.stringify(this.dBlocksBack))
        const block = dBlockBack.filter(x => x.pack === this.activeBlock.pack)[0]
        // console.log(block)
        this.$store.commit('main/setdBlockState', block)
        this.dialogBlocks = false
        this.dBlockSave('dBlocks', this.$store.state.main.dsb.dBlocks)
      },

      dataSetSelected (selectedTable) {
        // console.log(selectedTable.column)
        this.questions = selectedTable.column
      },

      varSelected (value) {
        // console.log(value)
      },

      restoreBlocks () {
        // Restore dBlocks
        this.$store.commit('main/setdBlocks', this.dBlocksBack)
        this.dBlockSave('dBlocks', this.dBlocksBack)
        // Restore fSettings
        this.$store.commit('main/setfSettingFull', this.fsettingsBack)
        this.dBlockSave('fsettings', this.fsettingsBack)
      },

      isdBlockActive (block) {
        // console.log(this.$store.getters['main/dBlockActive'])
        const activeDblocks = this.$store.state.main.dsb.dBlocks.filter(x => x.selected === true).map(x => x.pack)
        return activeDblocks.includes(block)
      },

      async clickk () {
        // console.log(this.isLocation)
      },

      // Save form settings into OCPU
      savefsettings () {
        // console.log('aha')
        // const settings = JSON.stringify(this.fsettings)
        // this.dBlockSave('fsettings', settings)
      },

      // Get Sync details and update
      // TODO Check if I'm using this. The same code in data-export.js file
      getdsSyncData (dsname) {
        // console.log(dsname)
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const main = this.$store.state.main
        const fpath = `${main.orgid}/${main.ap.prjid}/${main.afid}`
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocgetsyncdata/json`, { fpath: fpath, filetype: dsname })
            .then(response => {
              // console.log(response)
              if (response.data.length > 0) {
                const syncData = JSON.parse(response.data[0])
                const pos = this.dDatasets.map(function (e) { return e.title.toLowerCase() }).indexOf(dsname)
                this.dDatasets[pos].loading = false
                this.dDatasets[pos].syncdate = syncData.syncDate
                this.dDatasets[pos].link = syncData.link
              }
            })
        })
      },

      changeSize () {
        // console.log('aha')
      },

    },
  }
</script>

<style lang="sass">
  @import "~leaflet/dist/leaflet.css"
  @import "~leaflet.markercluster/dist/MarkerCluster.css"
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css"

  #coloured-line
    .ct-series-a .ct-line,
    .ct-series-a .ct-point
      stroke: #00cae3 !important

    .ct-series-b .ct-bar
      stroke: #f44336 !important

  .my-span
    float: right
</style>
