<template>
  <div>
    <v-card
      outlined
    >
      <v-toolbar
        flat
        style="z-index: 10"
        color="grey lighten-5"
        dense
      >
        <!-- Drag button -->
        <v-btn
          :color="color"
          class="mr-4"
          large
          fab
          dark
        >
          <v-icon
            class="handle"
          >
            mdi-map-marker-multiple
          </v-icon>
        </v-btn>
        <v-toolbar-title :class="`text-h3 ${color}--text text--darken-1`">
          {{ $t('dashboard.p-map') }}
        </v-toolbar-title>
        <v-spacer />

        <!-- Resize button -->
        <v-btn
          icon
          dark
          color="info"
          @click="resize"
        >
          <v-icon>mdi-arrow-up-down-bold</v-icon>
        </v-btn>

        <!-- Back map location -->
        <!-- <v-btn
          icon
          dark
          color="info"
          @click="backloc"
        >
          <v-icon>mdi-map-marker-multiple</v-icon>
        </v-btn> -->

        <!-- Delete button -->
        <!-- <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              color="error"
              v-on="on"
              @click="unselectBlock()"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $t('tooltip.delete') }}</span>
        </v-tooltip> -->
      </v-toolbar>

      <!-- Map section -->
      <div>
        <v-map
          :style="mapSize"
          :zoom="zoom"
          :center="center"
          :bounds="bounds"
        >
          <v-control-layers ref="control" />
          <v-tilelayer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base"
          />
          <v-marker-cluster
            v-if="dataLoaded"
            :options="clusterOptions"
            @clusterclick="click()"
          >
            <v-marker
              v-for="l in lMarkers"
              :key="l.position.id"
              :lat-lng="l.position"
            >
              <v-popup :content="l.position.id" />
            </v-marker>
          </v-marker-cluster>
        </v-map>
      </div>
    </v-card>
  </div>
</template>

<script>
  import { LMap, LTileLayer, LPopup, LMarker, LControlLayers } from 'vue2-leaflet'
  import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster'
  import L from 'leaflet'

  export default {
    name: 'MapApp',
    components: {
      'v-map': LMap,
      'v-tilelayer': LTileLayer,
      'v-marker-cluster': Vue2LeafletMarkercluster,
      'v-popup': LPopup,
      'v-marker': LMarker,
      'v-control-layers': LControlLayers,
    },
    data: () => ({
      blockBack: {
        pack: 'oclocation',
        title: 'mpref.prog-map',
        selected: false,
        tblname: [],
        question: true,
        variables: [
          { title: 'mpref.lat', value: '', type: 'string' },
          { title: 'mpref.lon', value: '', type: 'string' },
        ],
      },
      // Map data
      lMarkers: [],
      zoom: 4,
      center: L.latLng(40, 30),
      // centre: L.latLng(43.657019, -70.25949),
      bounds: null,
      maploc: {
        c: L.latLng(40, 30),
        b: null,
      },
      mapSize: 'height: 350px; z-index: 0',
      clusterOptions: {
        disableClusteringAtZoom: 18,
        chunkedLoading: true,
        maxClusterRadius: 50,
      },

      // https://leaflet-extras.github.io/leaflet-providers/preview/
      tileProviders: [
        {
          name: 'Google StreetMap',
          visible: true,
          url: 'https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}',
          attribution:
            '&copy; <a href="https://www.google.com/intl/en-US_US/help/terms_maps//">Google Maps, Terms of Service</a>',
        },
        {
          name: 'OpenStreetMap',
          visible: false,
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        },
        {
          name: 'StreetMap | ESRI',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
          attribution:
            'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        },
        {
          name: 'Satellite | ESRI',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution:
            'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        },
        {
          name: 'Open Topo Map',
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
            '&copy; <a href="https://opentopomap.org/">OpenTopoMap</a>, &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        },
        // {
        //   name: 'Stadia Alidade | Light',
        //   visible: false,
        //   url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
        //   attribution:
        //     '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        // },
        // {
        //   name: 'Dark | Stadia Maps',
        //   visible: false,
        //   url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
        //   attribution:
        //     '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        // },
      ],

      color: 'secondary',

      dataLoaded: false,
    }),

    computed: {
      //
    },

    created () {
      // Start data fetching
      this.getData()
    },

    mounted () {
      //
    },

    methods: {

      getData () {
        const main = this.$store.state.main
        const prop = {
          orgid: main.orgid,
          prjid: main.ap.prjid,
          formid: main.afid,
        }

        const ocpuPack = this.$store.state.ocpu.ocpuPack
        // console.log(mypacks)
        return new Promise((resolve, reject) => {
          this.$httpOcpu.post(`/${ocpuPack}/R/oclocation/json`, prop)
            .then(response => {
              // console.log(response.data)
              const result = response.data.map((element, index) => {
                const p = { position: { id: '', lat: '', lng: '' } }
                p.position.id = `id-${index}`
                p.position.lat = element.latitude
                p.position.lng = element.longitude
                return p
              })
              // console.log(result)

              this.lMarkers = result.slice()

              // Calculate bounds and center of the map
              const latmax = Math.max.apply(Math, result.map(function (o) { return o.position.lat }))
              const latmin = Math.min.apply(Math, result.map(function (o) { return o.position.lat }))
              const lngmax = Math.max.apply(Math, result.map(function (o) { return o.position.lng }))
              const lngmin = Math.min.apply(Math, result.map(function (o) { return o.position.lng }))
              this.center = L.latLng((latmax + latmin) / 2, (lngmax + lngmin / 2))
              this.bounds = L.latLngBounds(L.latLng(latmax, lngmax), L.latLng(latmin, lngmin))
              this.maploc.c = L.latLng((latmax + latmin) / 2, (lngmax + lngmin / 2))
              this.maploc.b = L.latLngBounds(L.latLng(latmax, lngmax), L.latLng(latmin, lngmin))

              // console.log(this.bounds)
              // console.log(this.center)

              // console.log(this.lMarkers)
              this.dataLoaded = true
            })
        })
      },

      resize () {
        let size
        // console.log(this.mapSize)
        switch (this.mapSize) {
          case 'height: 350px; z-index: 0':
            size = 500
            break
          case 'height: 500px; z-index: 0':
            size = 650
            break
          default:
            size = 350
        }
        this.mapSize = `height: ${size}px; z-index: 0`
      },

      backloc () {
        this.center = this.maploc.c
        // console.log(this.maploc.c)
        this.bounds = this.maploc.b
        // console.log(this.maploc.b)
      },

      clickme () {
        // console.log('clickec')
      },

      unselectBlock () {
        // console.log('unselect')
        const block = JSON.parse(JSON.stringify(this.blockBack))
        this.$store.commit('main/setdBlockState', block)
        // console.log(this.$parent.$parent)
        this.$parent.$parent.dBlockSave('dBlocks', this.$store.state.main.dsb.dBlocks)
      },
    },
  }
</script>

<style scoped>
@import "https://unpkg.com/leaflet@1.4.0/dist/leaflet.css";
@import "https://unpkg.com/leaflet.markercluster@1.4.1/dist/MarkerCluster.Default.css";

.leaflet-container {
  height: 97vh;
}
</style>
