<template>
  <v-card
    outlined
  >
    <v-toolbar
      flat
      color="grey lighten-5"
      dense
    >
      <!-- Drag button -->
      <v-btn
        :color="color"
        class="mr-4"
        fab
        large
        dark
      >
        <v-icon
          class="handle"
        >
          mdi-chart-timeline-variant
        </v-icon>
      </v-btn>

      <v-toolbar-title :class="`text-h3 ${color}--text text--darken-1`">
        {{ $t('dashboard.daily') }}
      </v-toolbar-title>
      <v-spacer />

      <!-- Delete button -->
    </v-toolbar>
    <div
      v-if="getDataStatus === 'loading'"
      class="loading"
    >
      <v-progress-linear
        indeterminate
        color="info"
      />
      ...{{ $t('tooltip.loading') }}
    </div>

    <!-- No submission info card -->
    <v-row
      v-if="!hasData"
      justify="center"
    >
      <v-card
        class="grey--text text--darken-0 pa-2"
        color="elevation-0"
        tile
      >
        {{ $t('dashboard.no-submission') }}
      </v-card>
    </v-row>

    <v-card-text>
      <v-list-item-group
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in progressTable"
          :key="i"
          inactive
          dense
          class="ma-0"
        >
          <v-list-item-content class="text-right">
            <v-list-item-title v-text="translateMe(item.name)" />
          </v-list-item-content>
          <v-list-item-content class="text-left px-4">
            <v-list-item-title v-text="`${item.value}`" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'BlockPie',

    components: {},

    data: () => ({
      getDataStatus: false,
      hasData: true,
      // Progress Table data
      progressTable: [],

      color: 'warning',
    }),

    computed: {
      //
    },

    created () {
      // Start data fetching
      this.getData()
    },

    mounted () {
      //
    },

    methods: {
      getData () {
        const main = this.$store.state.main
        const prop = {
          orgid: main.orgid,
          prjid: main.ap.prjid,
          formid: main.afid,
        }
        const ocpuPack = this.$store.state.ocpu.ocpuPack

        return new Promise((resolve, reject) => {
          this.getDataStatus = 'loading'
          this.$httpOcpu.post(`/${ocpuPack}/R/ocprogtab/json`, prop)
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                this.progressTable = response.data
                this.getDataStatus = true
              }
            })
          this.getDataStatus = true
        })
      },
      translateMe (val) {
        // console.log(val)
        return this.$t(val)
      },
    },
  }
</script>
<style lang="sass">
  #progressPie
    .ct-series-a .ct-slice-pie
      fill: #659D32 !important

    .ct-series-b .ct-slice-pie
      fill: #FF8C00 !important

</style>
