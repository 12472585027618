<template>
  <v-card
    outlined
  >
    <v-toolbar
      flat
      color="grey lighten-5"
      dense
    >
      <!-- Drag button -->
      <v-btn
        :color="color"
        class="mr-4"
        fab
        large
        dark
      >
        <v-icon
          class="handle"
        >
          mdi-chart-pie
        </v-icon>
      </v-btn>

      <v-toolbar-title :class="`text-h3 ${color}--text text--darken-1`">
        {{ $t('dashboard.pie-chart') }}
      </v-toolbar-title>
      <v-spacer />

      <!-- Delete button -->
    </v-toolbar>
    <div
      v-if="getDataStatus === 'loading'"
      class="loading"
    >
      <v-progress-linear
        indeterminate
        color="info"
      />
      ...{{ $t('tooltip.loading') }}
    </div>

    <!-- No submission info card -->
    <v-row
      v-if="!hasData"
      justify="center"
    >
      <v-card
        class="grey--text text--darken-0 pa-2"
        color="elevation-0"
        tile
      >
        {{ $t('dashboard.no-submission') }}
      </v-card>
    </v-row>
    <v-chart
      v-else
      class="chart"
      :option="pie"
    />
  </v-card>
</template>

<script>
  import { use } from 'echarts/core'
  import { CanvasRenderer } from 'echarts/renderers'
  import { PieChart } from 'echarts/charts'
  import {
    TooltipComponent,
    LegendComponent,
  } from 'echarts/components'
  import VChart, { THEME_KEY } from 'vue-echarts'

  use([
    CanvasRenderer,
    PieChart,
    TooltipComponent,
    LegendComponent,
  ])

  export default {
    name: 'BlockPie',
    components: {
      VChart,
    },
    provide: {
      [THEME_KEY]: 'light',
    },

    data: () => ({
      progDone: 0,
      progRemaining: 100,
      getDataStatus: false,
      hasData: true,
      // Pie chart data
      progressPie: {
        data: {
          // labels: [],
          series: [],
        },
        options: {
          labelInterpolationFnc: (value, idx) => `${value}`,
        },
      },
      color: 'success',
    }),

    computed: {
      pie () {
        return {
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          legend: {
            orient: 'vertical',
            right: '5%',
            data: [
              this.translateMe('hint.done'),
              this.translateMe('hint.remaining'),
            ],
          },
          series: [
            {
              name: this.translateMe('common.survey'),
              type: 'pie',
              radius: '85%',
              label: {
                formatter: '{b} : {c} ({d}%)',
              },
              data: [
                { value: this.progDone, name: this.translateMe('hint.done') },
                { value: this.progRemaining, name: this.translateMe('hint.remaining') },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
              color: this.colorPalette,
            },
          ],
        }
      },

      colorPalette () {
        return (['#00b04f', '#ffbf00'])
      },
    },

    created () {
      // Start data fetching
      this.getData()
    },

    mounted () {
      //
    },

    methods: {
      getData () {
        const main = this.$store.state.main
        const prop = {
          orgid: main.orgid,
          prjid: main.ap.prjid,
          formid: main.afid,
        }

        const ocpuPack = this.$store.state.ocpu.ocpuPack
        // Download data for each attached dashboard blocks
        return new Promise((resolve, reject) => {
          this.getDataStatus = 'loading'
          this.$httpOcpu.post(`/${ocpuPack}/R/ocprogpie/json`, prop)
            .then(response => {
              // console.log(response.data.hasdata[0])
              // console.log(response.data)
              if (response.status === 201) {
                this.progDone = response.data.series[0]
                this.progRemaining = response.data.series[1]
                this.getDataStatus = true
                // console.log(this.pie)
              }
            })
        })
      },

      translateMe (val) {
        return this.$t(val)
      },

    },
  }
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
