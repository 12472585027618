var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-4",attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-toolbar',{staticStyle:{"z-index":"1"},attrs:{"color":"elevation-0","dense":""}},[_c('v-toolbar-title',{staticClass:"text-h2 grey--text text--darken-0 pr-8"},[_vm._v(" "+_vm._s(_vm.$store.state.main.af.title)+" ")]),_c('v-spacer'),(_vm.myRole() === 'manager')?_c('v-btn',{attrs:{"color":"warning","fab":"","dark":""},on:{"click":function($event){_vm.helptoolbar = !_vm.helptoolbar}}},[_c('v-icon',[_vm._v("mdi-cog")])],1):_vm._e()],1),(!_vm.helptoolbar)?_c('v-card',{staticClass:"mt-0",attrs:{"color":"elevation-0"}},[_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},_vm._l((_vm.$store.state.main.dsb.dBlocks),function(block,i){return _c('v-card-text',{key:i,staticClass:"py-2"},[_c('v-checkbox',{staticClass:"py-0 my-0",attrs:{"label":_vm.translateMe(block.title),"hide-details":"","disabled":_vm.myRole()==='viewer'},on:{"click":function($event){return _vm.dblockSelected({ item: i, state: block.selected, block: block})}},model:{value:(block.selected),callback:function ($$v) {_vm.$set(block, "selected", $$v)},expression:"block.selected"}})],1)}),1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card-text',[_c('v-row',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-8",attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.restoreBlocks()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-restore-alert ")])],1)]}}],null,false,3810420718)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.restore')))])])],1),(_vm.$store.state.main.dsb.dBlocks[0].selected || _vm.$store.state.main.dsb.dBlocks[1].selected)?_c('div',[_c('validation-observer',{ref:"observer"},[_c('form',[_c('validation-provider',{attrs:{"name":_vm.$t('oth.number'),"rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.myRole()==='viewer',"label":_vm.$t('mpref.target'),"type":"number","error-messages":errors,"dense":""},model:{value:(_vm.surveyTotal),callback:function ($$v) {_vm.surveyTotal=$$v},expression:"surveyTotal"}})]}}],null,false,3130306977)})],1)])],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"pb-4 px-8"},[_c('v-spacer'),_c('v-checkbox',{staticClass:"py-0 my-0",attrs:{"label":_vm.$t('dashboard.keep-closed'),"hide-details":""},model:{value:(_vm.dBInfo),callback:function ($$v) {_vm.dBInfo=$$v},expression:"dBInfo"}})],1)],1)],1):_vm._e(),_c('block-dashboard',{ref:"blockDashboard"}),_c('draggable',{attrs:{"group":"dblocks","handle":".handle"},on:{"start":function($event){_vm.drag=true},"end":function($event){_vm.drag=false}}},[(_vm.isdBlockActive('oclocation'))?_c('block-l-map',{ref:"blockMap"}):_vm._e(),(_vm.isdBlockActive('ocprogpie'))?_c('block-prog-pie',{ref:"blockPie"}):_vm._e(),(_vm.isdBlockActive('ocprogtab'))?_c('block-prog-tab',{ref:"blockProgress"}):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialogBlocks),callback:function ($$v) {_vm.dialogBlocks=$$v},expression:"dialogBlocks"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('mpref.select-var'))+" ")]),_c('v-card-text',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateBlock)}}},[_c('validation-provider',{attrs:{"name":_vm.$t('common.table'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataSet,"error-messages":errors,"item-text":"label[0]","item-value":"table[0]","prepend-icon":"mdi-table","label":_vm.$t('mpref.select-tbl'),"return-object":"","validate-on-blur":""},on:{"change":_vm.dataSetSelected},model:{value:(_vm.selectedSection),callback:function ($$v) {_vm.selectedSection=$$v},expression:"selectedSection"}})]}}],null,true)}),_vm._l((_vm.activeBlock.variables),function(t,i){return _c('div',{key:t.title},[(_vm.activeBlock.question)?_c('validation-provider',{attrs:{"name":t.title,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.questions,"error-messages":errors,"item-text":"label","item-value":"id","prepend-icon":"mdi-map-marker-plus","label":_vm.translateMe(t.title)},on:{"change":function($event){return _vm.varSelected(i)}},model:{value:(_vm.selectedVar[i]),callback:function ($$v) {_vm.$set(_vm.selectedVar, i, $$v)},expression:"selectedVar[i]"}})]}}],null,true)}):_c('validation-provider',{attrs:{"name":t.title,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":t.type,"value":t.value,"error-messages":errors,"label":t.title},model:{value:(_vm.selectedVar[i]),callback:function ($$v) {_vm.$set(_vm.selectedVar, i, $$v)},expression:"selectedVar[i]"}})]}}],null,true)})],1)}),_c('v-row',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.dblockCancelled('aahh')}}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],2)]}}])})],1)],1)],1),_c('snack-bar-card',{attrs:{"snackbar":_vm.snackBar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }